<script lang="ts">
	import IndustriesTabContent from './IndustriesTabContent.svelte';
	import { swipe } from 'svelte-gestures';
	import { onMount } from 'svelte';
	import { CarouselHelper } from '$lib/helpers/swipe.helper';
	import type { Industry } from "$lib/domain/industry.interface";
	export let data: Industry [] = [];
	export let title: string|undefined;

	let activeTabIndex = 0;
	let activeIndex = 0;
	const maxCount = data.length;
	const carouselHelper = CarouselHelper.getInstance(maxCount);

	onMount(() => {
			if (!window.matchMedia('(max-width: 1400px)').matches) {
					return;
			}

			carouselHelper.resetInterval();
			carouselHelper.indexStore.subscribe((index) => (activeIndex = Number(index)));
	});
</script>

<div style="overflow: hidden">
	<div class="container flex-column ">
		<div class="section-title flex-column">
			<h2>{@html title}</h2>
		</div>
		
		<div class="tab-container">
			<div class="tab-strip flex-row">
				{#each data as industry, i}
					<button
						on:click="{() => (activeTabIndex = i)}"
						class="tab-strip-button"
						class:active="{activeTabIndex === i}">
						{industry.title}
					</button>
				{/each}
			</div>
		</div>
		
		<div
			class="swipe-container"
			use:swipe="{{ timeframe: 300, minSwipeDistance: 60, touchAction: 'pan-y' }}"
			on:swipe="{carouselHelper.handleSwipe}">
			{#each data as industry, i}
				<div class="industries-block" class:active="{activeTabIndex === i}">
					<IndustriesTabContent industry="{industry}" />
				</div>
			{/each}
		</div>
	</div>
</div>

<style lang="scss">
	@import '$styles/vars.scss';

	.section-title {
		align-items: center;
	}
	.swipe-container {
		.industries-block {
			display: none;
			opacity: 0;

			&.active {
				display: flex;
				opacity: 1;
				animation-name: fadeInOpacity;
				animation-iteration-count: 1;
				animation-timing-function: ease-in;
				animation-duration: 250ms;
			}
		}
	}

	@media (max-width: $portrait-phone-size) {
		.tab-container {
			margin-top: 24px;
			.tab-strip {
				gap: 6px;
				&-button {
					padding: 10px 8px;
					font-size: 12px;
					font-weight: 300;
				}
			}

		}
	}
	@keyframes fadeInOpacity {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
</style>
